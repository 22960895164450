<template>
  <el-dialog
    v-model="isUpload"
    title="插入图片"
    width="600px"
    draggable
    custom-class="input-img"
    @close="onClose"
  >
    <el-upload
      class="editor-upload"
      ref="upload"
      drag
      action
      multiple
      :http-request="handleUpload"
      :on-progress="
        (progress) => {
          console.log('----111', progress);
        }
      "
      :auto-upload="true"
      :limit="20"
      :on-exceed="handleExceed"
      :on-success="handleSuccess"
      accept=".jpg,.jpeg,.png,.JPG,.JPEG"
    >
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">
        拖拽到这里或者 <em class="el-dm">点击选择图片</em>上传
      </div>
      <!-- <template #tip>
        <div class="el-upload__tip">
          jpg/png files with a size less than 500kb
        </div>
      </template> -->
    </el-upload>
  </el-dialog>
</template>

<script>
import { blobToBase64 } from "../../workers/utils/utils";

export default {
  name: "UploadImage",
  props: {
    show: { type: Boolean, default: false },
    articleId: { type: String, default: "" },
    originalImage: { type: Boolean, default: false },
  },
  data() {
    return {
      isUpload: false,
      fileList: [],
    };
  },
  watch: {
    show(val) {
      this.isUpload = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("update:show", false);
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    isLocal() {
      return this.articleId && this.articleId.startsWith("local-");
    },
    handleUpload(op) {
      this.$api.upload
        .uploadFile(op.file, this.isLocal(), (percent) => {
          op.onProgress(percent);
        })
        .then((res) => {
          console.log("---res", res);
          op.onSuccess({ ...res });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleExceed(files, fileList) {
      this.$message({
        message: `当前限制选择 20 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`,
        type: "warning",
      });
    },
    handleSuccess(response, file, fileList) {
      const url = this.originalImage ? response.fileUrl : response.localUrl;
      this.$emit("success", url);
      this.fileList.push(url);
      if (this.fileList.length === fileList.length) {
        this.isUpload = false;
      }
      // blobToBase64(response.blob).then((src) => {
      //   this.$emit("success", src);
      //   this.fileList.push(url);
      //   if (this.fileList.length === fileList.length) {
      //     this.isUpload = false;
      //   }
      // });
    },
  },
};
</script>

<style scoped></style>
